<template>
    <section class="op-calendario w-100">
        <div class="row justify-content-center">
            <div class="col-12">
              <!--  <calendario-year :opciones="data_tmp" /> -->
            </div>
        </div>
        <!-- partials -->
    </section>
</template>
<script>

export default {
    components:{
        CalendarioYear: () => import('./CalendarioYear'),
    },
    data(){
        return{
           data_tmp: []
        }
    },
    methods:{
     
    }
}
</script>
<style lang="scss" scoped>
.op-calendario{
    height: calc(100vh - 390px);
    
}
</style>